import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { firstValueFrom, map, take } from 'rxjs';
import { Role } from '../../shared/enums/role';

/**
 * Guarda de rota para acesso apenas a profissionais logados.
 *
 * @param route - Objeto de rota.
 * @param state - Estado da rota.
 * @returns Promise<boolean> - Retorna um observable que emite true se o usuário estiver logado, caso contrário, retorna false.
 */
export const RoleGuard: CanActivateFn = (route, state) => {
  // Injeta o serviço de autenticação
  const authService = inject(AuthService);
  const router = inject(Router);
  const isLoggedIn = authService.isLoggedIn.pipe(map(isLoggedIn => isLoggedIn ? true : false), take(1));

  if (!isLoggedIn) {
    authService.logout();
    router.navigate(['/login']);
    return false;
  }

  const allowedRoles = route.data['roles'] as Array<Role>;

  if (authService.hasAnyRole(allowedRoles)) {
    return true;
  } else {
    return false;
  }

  // // Retorna um observable que emite true se o usuário estiver logado, caso contrário, retorna false
  // return authService.isLoggedIn.pipe(
  //   map(isLoggedIn => isLoggedIn ? true : false), // Mapeia o valor de isLoggedIn para true ou false
  //   take(1) // Toma apenas o primeiro valor emitido
  // );
};
